import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LandlordSection from "./components/sections/LandlordSection";
import PManagerSection from "./components/sections/PManagerSection";
import LandlordInfoTabComponent from "./components/LandlordInfoTabComponent";
import { Redirect } from "react-router-dom";
import {
  fetchLandlordDetails,
  fetchLandlordBankDetails,
  fetchPManagerBankDetails,
  fetchPManagerDetails
} from "./utils";

export default function LandLordInfo() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [landlordDetails, setLandlordDetails] = useState({});
  const [pManagerDetails, setPManagerDetails] = useState({});
  const [pManagerBankDetails, setPManagerBankDetails] = useState({});
  const [landlordbankDetails, setLandlordBankDetails] = useState({});

  useEffect(() => {
    if (user?.roles.includes("Landlord")) {
      fetchLandlordDetails(user, setLandlordDetails, dispatch);
      fetchLandlordBankDetails(user, setLandlordBankDetails, dispatch);
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user?.roles.includes("Agent")) {
      fetchPManagerDetails(user, setPManagerDetails, dispatch);
      fetchPManagerBankDetails(user, setPManagerBankDetails, dispatch);
    }
  }, [dispatch, user]);

  if (user?.roles.includes("Landlord") && user?.roles.includes("Agent"))
    return (
      <LandlordInfoTabComponent
        landlordDetails={landlordDetails}
        setLandlordDetails={setLandlordDetails}
        pManagerDetails={pManagerDetails}
        setPManagerDetails={setPManagerDetails}
        landlordbankDetails={landlordbankDetails}
        setLandlordBankDetails={setLandlordBankDetails}
        pManagerBankDetails={pManagerBankDetails}
        setPManagerBankDetails={setPManagerBankDetails}
      />
    );
  else if (user?.roles.includes("Landlord") && !user?.roles.includes("Agent"))
    return (
      <LandlordSection
        landlordDetails={landlordDetails}
        setLandlordDetails={setLandlordDetails}
        landlordbankDetails={landlordbankDetails}
        setLandlordBankDetails={setLandlordBankDetails}
      />
    );
  else if (user?.roles.includes("Agent") && !user?.roles.includes("Landlord"))
    return (
      <PManagerSection
        pManagerDetails={pManagerDetails}
        setPManagerDetails={setPManagerDetails}
        pManagerBankDetails={pManagerBankDetails}
        setPManagerBankDetails={setPManagerBankDetails}
      />
    );
  else return <Redirect to={"/account-setting"} />;
}
