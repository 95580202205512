import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, FormHelperText } from "@mui/material";
import CustomInputAndLabelField from "#/components/customInputFields/CustomInputAndLabelField";
import CustomSelectField from "#/components/customInputFields/customSelectField/CustomSelectField";
import CustomMuiBtn from "../CustomMUIBtn";
import { showNotifications } from "#/store/actions/notification.actions";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { walletServiceApi } from "#/apis/izyPayApi";
import { fetchLandlordBankDetails, fetchLandlordDetails } from "../../utils";

const BankDetailsForm = ({
  role,
  setState,
  setLandlordDetails,
  setLandlordBankDetails
}) => {
  const dispatch = useDispatch();
  const [validating, setValidating] = useState(false);
  const bankList = useSelector((state) => state.paystack?.banksInNigeria);
  const user = useSelector((state) => state.auth.user);

  const bankOptions = bankList.map((bank) => ({
    label: bank.name,
    value: bank.id
  }));

  const formik = useFormik({
    initialValues: {
      accName: "",
      accNumber: "",
      bank: ""
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      await addBank(values, resetForm);
    }
  });

  const addBank = async (values, resetForm) => {
    try {
      const userRole = role === "landlord" ? "property-owner" : "property-manager";
      const selectedBank = bankList.find(({ id }) => id === values.bank.value);

      const payload = {
        code: selectedBank.code,
        id: values.bank.value,
        name: selectedBank.name,
        account_number: values.accNumber,
        account_name: values.accName
      };

      const response = await propertyServiceApi().patch(
        `/${userRole}/bank/${user.liveizy_id}`,
        payload
      );

      dispatch(showNotifications("Bank Account added", "success"));
      resetForm();
      setState(response.data.payload);
      fetchLandlordDetails(user, setLandlordDetails, dispatch);
      fetchLandlordBankDetails(user, setLandlordBankDetails, dispatch);
    } catch (error) {
      const errorMessage = error.response?.data?.error || "An error occurred";
      dispatch(showNotifications(errorMessage, "error"));
    }
  };

  const validateBankAccount = async (bankId, accountNumber) => {
    setValidating(true);
    try {
      const { data } = await walletServiceApi().get(
        `/user/validate/nuban/${accountNumber}/bank/${bankId}`
      );
      formik.setFieldValue("accName", data.data.accountName);
    } catch (error) {
      dispatch(showNotifications("Could not resolve account name", "error"));
    } finally {
      setValidating(false);
    }
  };

  useEffect(() => {
    const { bank, accNumber } = formik.values;
    if (bank && accNumber.length === 10) {
      validateBankAccount(bank.value, accNumber);
    }
  }, [formik.values.bank, formik.values.accNumber]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* Bank Name Field */}
        <Grid item xs={12} sm={6}>
          <CustomSelectField
            label="Bank Name"
            placeholder="Select Bank..."
            options={bankOptions}
            isLoading={!bankList.length}
            name="bank"
            onChange={(selectedOption) => formik.setFieldValue("bank", selectedOption)}
            value={formik.values.bank}
          />
          {formik.errors.bank && (
            <FormHelperText error>{formik.errors.bank}</FormHelperText>
          )}
        </Grid>

        {/* Account Number Field */}
        <Grid item xs={12} sm={6}>
          <CustomInputAndLabelField
            label="Account Number"
            type="text"
            placeholder="Enter Account Number"
            name="accNumber"
            onChange={formik.handleChange}
            value={formik.values.accNumber}
            maxLength={10}
          />
          {formik.errors.accNumber && (
            <FormHelperText error>{formik.errors.accNumber}</FormHelperText>
          )}
          {validating && (
            <FormHelperText style={{ color: "#10609f" }}>Validating...</FormHelperText>
          )}
        </Grid>

        {/* Account Name Field */}
        <Grid item xs={12} sm={6}>
          <CustomInputAndLabelField
            label="Account Name"
            type="text"
            placeholder="Account Name"
            name="accName"
            disabled
            value={formik.values.accName}
          />
          {formik.errors.accName && (
            <FormHelperText error>{formik.errors.accName}</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Box textAlign="center" mt={3}>
        <CustomMuiBtn type="submit">Submit</CustomMuiBtn>
      </Box>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.accName) {
    errors.accName = "Account name is required";
  }

  if (!values.accNumber) {
    errors.accNumber = "Account number is required";
  } else if (values.accNumber.length !== 10 || !/^\d+$/.test(values.accNumber)) {
    errors.accNumber = "Account number must be 10 digits";
  }

  if (!values.bank) {
    errors.bank = "Please select a bank";
  }

  return errors;
};

export default BankDetailsForm;
