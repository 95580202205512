import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAddressDropdown from "#/hooks/propertyservice-hooks/useAddressDropdown";
import useAddMultipleImages from "#/hooks/propertyservice-hooks/useAddMultipleImages";
import {
  generatePropertyId,
  createProperty,
  updateProperty
} from "#/store/actions/property.actions";
import useArrowDropdown from "./useArrowDropdown";
import { showNotifications } from "#/store/actions/notification.actions";
import {
  CREATED_FOR,
  OPTIONS_PROPERTY_TYPE,
  PROPERTY_ROLES
} from "#/components/propertyForm/propertyForm.constants";

const usePropertyForm = (isForEdit = false, history, data) => {
  //------------------------------------------------------------------
  //Store
  //------------------------------------------------------------------
  const liveizy_id = useSelector((state) => state.auth.user.liveizy_id);
  const role = useSelector((state) => state.auth.user.roles);

  const { propertyId, creatingProperty } = useSelector((state) => state.property); //Used when creating a property

  //------------------------------------------------------------------
  //Helper hooks
  //------------------------------------------------------------------
  const dispatch = useDispatch();

  const COMPANIES = data
    ? [
        { mainValue: "select", displayValue: "Select Company" },
        ...data.data.map((team) => ({
          mainValue: team?.business?._id || "unknown",
          displayValue: team?.business?.legal_name || "Unknown Company"
        }))
      ]
    : [{ mainValue: "select", displayValue: "Select Company" }];

  // console.log(COMPANIES);

  //custom hooks
  const { addressDropdownHelpers } = useAddressDropdown();
  const { addMultipleImagesHelpers } = useAddMultipleImages();
  const propertyTypeHelpers = useArrowDropdown(OPTIONS_PROPERTY_TYPE);
  const createPropertyAs = useArrowDropdown(PROPERTY_ROLES);
  const createPropertyFor = useArrowDropdown(CREATED_FOR);
  const companyCreatedFor = useArrowDropdown(COMPANIES);

  //------------------------------------------------------------------
  //States
  //------------------------------------------------------------------
  const [propertyCoverImage, setPropertyCoverImage] = useState();
  const [propertyCoverImagePreview, setPropertyCoverImagePreview] = useState();
  const [previouslyUploadedImages, setPreviouslyUploadedImages] = useState([]);
  const [propertyName, setPropertyName] = useState("");
  const [errors, setErrors] = useState({
    propertyCoverImage: false,
    propertyName: false,
    propertyAddress: false,
    propertyType: false,
    createPropertyAs: false,
    createPropertyFor: false,
    companyCreatedFor: false
  });

  //------------------------------------------------------------------
  //Function declarations
  //------------------------------------------------------------------
  //Property Name update and validation
  const handleSetPropertyName = useCallback((e) => {
    setPropertyName(e.target.value);
  }, []);

  //Upload property cover Image
  const handleSetCoverImage = (e) => {
    const file = e.target.files[0];
    setPropertyCoverImage(file);

    //If user did not select any file, get out of function
    if (file === undefined) return;

    const reader = new FileReader();

    reader.onload = function () {
      setPropertyCoverImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleRemovePreviouslyUploadedImage = useCallback(
    (index) => {
      const filteredData = previouslyUploadedImages.filter((item, i) => {
        return index !== i;
      });

      setPreviouslyUploadedImages(filteredData);
    },
    [previouslyUploadedImages]
  );

  //Setting errors
  const handleSetErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: true
      };
    });
  }, []);

  //removing errors(NOTE: this was not merged into the handleSetErrors function because of some edge cases)
  const handleRemoveErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: false
      };
    });
  }, []);

  const { houseNumber, line, state, lga } = addressDropdownHelpers.address;

  useEffect(() => {
    if (
      houseNumber.length !== 0 &&
      line.length !== 0 &&
      state.length !== 0 &&
      lga.length !== 0
    ) {
      handleRemoveErrors("propertyAddress");
    }
  }, [houseNumber, line, state, lga]);

  useEffect(() => {
    if (propertyCoverImage) {
      handleRemoveErrors("propertyCoverImage");
    }
  }, [propertyCoverImage]);

  useEffect(() => {
    if (propertyName.trim().length !== 0) {
      handleRemoveErrors("propertyName");
    }
  }, [propertyName]);

  useEffect(() => {
    if (propertyTypeHelpers.selectedOption.mainValue !== "selectType") {
      handleRemoveErrors("propertyType");
    }
  }, [propertyTypeHelpers.selectedOption.mainValue]);

  useEffect(() => {
    if (createPropertyAs.selectedOption.mainValue !== "select") {
      handleRemoveErrors("createPropertyAs");
    }
  }, [createPropertyAs.selectedOption.mainValue]);

  useEffect(() => {
    if (createPropertyFor.selectedOption.mainValue !== "select") {
      handleRemoveErrors("createPropertyFor");
    }
  }, [createPropertyFor.selectedOption.mainValue]);

  useEffect(() => {
    if (companyCreatedFor.selectedOption.mainValue !== "select") {
      handleRemoveErrors("companyCreatedFor");
    }
  }, [companyCreatedFor.selectedOption.mainValue]);

  //Submission of form to create property
  const handleSubmitProperty = (e, isUpdating, sentPropertyId) => {
    e.preventDefault();

    const { address } = addressDropdownHelpers;
    const { houseNumber, line, state, lga, lcda, otherAddress } = address;
    const { allMultipleImages } = addMultipleImagesHelpers;

    let created_by = "";
    let createdFor = createPropertyFor.selectedOption.mainValue;
    let companyCreated = companyCreatedFor.selectedOption.mainValue;
    let companyNameCreated = companyCreatedFor.selectedOption.displayValue;

    if (role.includes("Landlord") && role.includes("Agent")) {
      created_by = createPropertyAs.selectedOption.mainValue;
    } else if (role.includes("Landlord") && !role.includes("Agent")) {
      created_by = "landlord";
    } else if (!role.includes("Landlord") && role.includes("Agent")) {
      created_by = "agent";
    }

    //--------------------------------------------
    //Validations
    //--------------------------------------------
    if (
      !propertyCoverImage ||
      propertyName.length === 0 ||
      houseNumber.length === 0 ||
      line.length === 0 ||
      state.length === 0 ||
      lga.length === 0 ||
      propertyTypeHelpers.selectedOption.mainValue === "selectType" ||
      (createPropertyFor.selectedOption.mainValue === "personal" &&
        createPropertyAs.selectedOption.mainValue === "select")
    ) {
      dispatch(showNotifications("Please Fill all required fields", "error"));
    }

    if (!propertyCoverImage) {
      return handleSetErrors("propertyCoverImage");
    }

    if (propertyName.length === 0) {
      return handleSetErrors("propertyName");
    }

    if (
      houseNumber.length === 0 ||
      line.length === 0 ||
      state.length === 0 ||
      lga.length === 0
    ) {
      return handleSetErrors("propertyAddress");
    }

    if (propertyTypeHelpers.selectedOption.mainValue === "selectType") {
      return handleSetErrors("propertyType");
    }

    if (
      createPropertyAs.selectedOption.mainValue === "select" &&
      role.includes("Landlord") &&
      role.includes("agent")
    ) {
      return handleSetErrors("createPropertyAs");
    }

    if (createPropertyFor.selectedOption.mainValue === "select") {
      return handleSetErrors("createPropertyFor");
    }

    if (
      companyCreatedFor.selectedOption.mainValue === "select" &&
      createPropertyFor.selectedOption.mainValue === "company"
    ) {
      return handleSetErrors("companyCreatedFor");
    }
    //--------------------------------------------
    //--------------------------------------------
    //--------------------------------------------

    const formData = new FormData();

    formData.append("title", propertyName);
    formData.append("house_number", houseNumber);
    formData.append("street", line);
    formData.append("state", state);
    formData.append("lga", lga);
    formData.append("lcda", lcda || "");
    formData.append("property_type", propertyTypeHelpers.selectedOption.mainValue);
    formData.append("property_id", propertyId || sentPropertyId);
    formData.append("country", "Nigeria");
    formData.append("property_images", propertyCoverImage);
    formData.append("createdFor", createdFor);
    formData.append("other_address", otherAddress);
    formData.append("otherManagers", []);
    createPropertyFor.selectedOption.mainValue === "personal"
      ? formData.append("created_by", created_by)
      : formData.append("created_by", PROPERTY_ROLES[2].mainValue);
    createPropertyFor.selectedOption.mainValue === "company" &&
      formData.append("companyId", companyCreated);
    createPropertyFor.selectedOption.mainValue === "company" &&
      formData.append("companyName", companyNameCreated);

    // Handle previously uploaded images
    if (previouslyUploadedImages?.length) {
      previouslyUploadedImages.forEach((image) => {
        formData.append("image_urls", image);
      });
    }

    // Handle newly uploaded multiple images
    if (allMultipleImages?.length) {
      allMultipleImages.forEach((image) => {
        formData.append("property_images", image);
      });
    }

    // Conditionally append owner or property manager based on the creator
    if (
      createPropertyFor.selectedOption.mainValue === "company" ||
      created_by === "landlord"
    ) {
      formData.append("owner", liveizy_id);
    } else if (created_by === "agent") {
      formData.append("property_manager", liveizy_id);
    }

    if (isUpdating) {
      dispatch(updateProperty(formData, sentPropertyId, history));
    } else {
      dispatch(
        createProperty(formData, () => {
          setPropertyName("");
          addressDropdownHelpers.setAddress({
            houseNumber: "",
            line: "",
            state: "",
            lga: "",
            lcda: ""
          });
          propertyTypeHelpers.setSelectedOption(OPTIONS_PROPERTY_TYPE[0]);
          addMultipleImagesHelpers.setAllMultipleImages();
          addMultipleImagesHelpers.setAllMultipleImagesPreview([]);
          history.push(`/create/${propertyId}/apartment`);
        })
      );
    }
  };

  //Get a random property id from the backend
  useEffect(() => {
    if (!isForEdit) {
      dispatch(generatePropertyId());
    }
  }, [dispatch, isForEdit]);

  return {
    propertyFormHelpers: {
      propertyId,
      handleSetCoverImage,
      propertyCoverImage,
      propertyCoverImagePreview,
      propertyName,
      handleSetPropertyName,
      addressDropdownHelpers,
      addMultipleImagesHelpers,
      propertyTypeHelpers,
      errors,
      handleSetErrors,
      handleSubmitProperty,
      creatingProperty,
      setPropertyCoverImage,
      setPropertyCoverImagePreview,
      setPropertyName,
      previouslyUploadedImages,
      setPreviouslyUploadedImages,
      handleRemovePreviouslyUploadedImage,
      createPropertyAs,
      createPropertyFor,
      companyCreatedFor
    }
  };
};

export default usePropertyForm;
