import { authServiceApi } from "#/apis/authServiceApi";
import ProfilePictureWrapper from "#/components/ui-components/ProfilePictureWrapper";
import SubtitleAndSubtext from "#/components/ui-components/SubtitleAndSubtext.component";
import {
  alertError,
  alertSuccess,
  showNotifications
} from "#/store/actions/notification.actions";
import avatar from "#/assets/propertyPage/avatar.png";

import {
  addTenantByBioData,
  createTenantByLid,
  getTenantsApartment,
  getTenantsUnderPropertyManager
} from "#/store/actions/tenant.actions";
import {
  Dialog,
  Button,
  DialogTitle,
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  TextField,
  CircularProgress,
  Grid
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../customInput/customInput";
import { DetailsContainer } from "#/components/cards/DetailsCardContainer.component";
import { getPropertyById } from "#/store/actions/property.actions";
import { copyTextToClipboard } from "#/shared/utils";

const NONE = "None";
const BYLID = "liveizy-id";
const BYBIO = "bio-data";

const Title = styled(DialogTitle)`
  color: #052137;
  font-weight: bold;
  margin: 10px auto;
  text-align: center;
  max-width: 75%;
`;

const Btn = styled(Button)`
  width: 100px;
  height: 35px;
`;

const AddTenantModal = ({ apartmentId, setShowTenantForm }) => {
  const [modeOfInvite, setModeOfInvite] = useState(NONE);
  const { propertyById } = useSelector((state) => state.property);

  const closeModal = () => {
    setShowTenantForm(false);
  };

  const backToOptions = () => setModeOfInvite(NONE);

  let component;
  switch (modeOfInvite) {
    case NONE:
      component = (
        <OptionsTab
          closeModal={closeModal}
          modeOfInvite={modeOfInvite}
          setModeOfInvite={setModeOfInvite}
        />
      );
      break;

    case BYLID:
      component = (
        <AddByLid
          closeModal={closeModal}
          apartmentId={apartmentId}
          propertyId={propertyById._id}
          backToOptions={backToOptions}
        />
      );
      break;
    case BYBIO:
      component = (
        <AddByBio
          closeModal={closeModal}
          propertyId={propertyById._id}
          backToOptions={backToOptions}
          apartmentId={apartmentId}
        />
      );
      break;

    default:
      break;
  }
  return (
    <Dialog onClose={closeModal} open={true}>
      {component}
    </Dialog>
  );
};

const OptionsTab = ({ modeOfInvite, setModeOfInvite, closeModal }) => {
  const [value, setValue] = useState(NONE);
  const handleOnClick = () => {
    setModeOfInvite(value);
  };
  return (
    <>
      <Title>Select Preferred Option For Adding A Tenant</Title>
      <FormControl
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={(event) => setValue(event.target.value)}>
          <FormControlLabel
            value={BYLID}
            control={<Radio size="small" />}
            label="By Liveizy ID"
          />
          <FormControlLabel
            value={BYBIO}
            control={<Radio size="small" />}
            label="By Bio"
          />
        </RadioGroup>
      </FormControl>
      <Stack direction={"row"} spacing={2} margin={2} justifyContent="center">
        <Btn onClick={closeModal} variant="outlined">
          Cancel
        </Btn>
        <Btn sx={{ background: "#10609f" }} variant="contained" onClick={handleOnClick}>
          Continue
        </Btn>
      </Stack>
    </>
  );
};

// -----------------------------------------
// -----------------------------------------
// ADD TENANTS BY LIVEIZY ID
// -----------------------------------------
// -----------------------------------------

const AddByLid = ({ apartmentId, backToOptions, closeModal, propertyId }) => {
  const dispatch = useDispatch();

  // store
  const creatingTenants = useSelector((state) => state.tenant.creatingTenants);
  const apartmentTenant = useSelector((state) => state.tenant.apartmentTenant);
  const user = useSelector((state) => state.auth.user);
  const storeloading = useSelector((state) => state.auth.loading);

  const [lid, setLid] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  const handleGetTenant = () => {
    if (lid.length !== 8) {
      return setError("Liveizy ID should be 8 Characters");
    }
    setError("");
    setLoading(true);
    authServiceApi()
      .get(`/user/validate/liveizy/${lid}`)
      .then((res) => {
        setUserDetails(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        setError(error?.response?.data?.message || "Invalid Liveizy ID");
        dispatch(
          showNotifications(
            error?.response?.data?.message || "Invalid Liveizy ID",
            "error"
          )
        );
      });
  };

  const handleAddTenant = () => {
    if (!userDetails?.email || !userDetails?.name || !userDetails?.phone || !lid) {
      return dispatch(
        showNotifications(
          "Tenant's Full name, email, phone number and Liveizy ID must exist",
          "error"
        )
      );
    }
    const payload = {
      userId: userDetails?.liveizy_id,
      email: userDetails?.email,
      fullname: userDetails?.name,
      phone_number: userDetails?.phone,
      apartment_id: `${apartmentId}`
    };
    dispatch(
      createTenantByLid(payload, propertyId, () => {
        dispatch(getPropertyById(propertyId, "charges", "tenant"));
        dispatch(getTenantsUnderPropertyManager(user.liveizy_id));
        dispatch(getTenantsApartment(user.liveizy_id));
        closeModal();
      })
    );
  };

  return (
    <>
      {userDetails === null ? (
        <>
          <Title>Enter Tenant Liveizy ID Below</Title>
          <TextField
            sx={{ maxWidth: "180px", margin: "15px auto" }}
            label="Liveizy ID"
            value={lid}
            onChange={(e) => setLid(e.target.value)}
            error={error !== ""}
            helperText={error}
          />
          <Stack direction={"row"} spacing={2} margin={2} justifyContent="center">
            <Btn disabled={loading} variant="outlined" onClick={backToOptions}>
              Back
            </Btn>
            <Btn
              disabled={loading}
              sx={{ background: "#10609f" }}
              variant="contained"
              onClick={handleGetTenant}>
              Continue
            </Btn>
          </Stack>
          {loading && <CircularProgress sx={{ margin: "10px auto" }} />}
        </>
      ) : (
        <>
          <Title>User Details</Title>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "10px 20px",
              width: "100%"
            }}>
            <ProfilePictureWrapper
              src={userDetails?.profile_image ? userDetails?.profile_image : avatar}
              alt={"Tenant's profile Picture"}
            />
            <DetailsContainer
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}>
              <SubtitleAndSubtext
                sm={4}
                xs={12}
                lg={4}
                style={{ width: "auto", textAlign: "center" }}
                subtitle={"Tenant's Email"}
                subtext={userDetails?.email}
              />
              <SubtitleAndSubtext
                sm={4}
                xs={12}
                lg={4}
                style={{ width: "auto", textAlign: "center" }}
                subtitle={"Tenant's Fullname"}
                subtext={userDetails?.name}
              />
              <SubtitleAndSubtext
                sm={4}
                xs={12}
                lg={4}
                style={{ width: "auto", textAlign: "center" }}
                subtitle={"Tenant's Phone Number"}
                subtext={userDetails?.phone}
              />
            </DetailsContainer>
            <Stack direction={"row"} spacing={2} margin={2} justifyContent="center">
              <Btn variant="outlined" onClick={() => setUserDetails(null)}>
                Back
              </Btn>
              <Btn
                sx={{ background: "#10609f" }}
                variant="contained"
                onClick={handleAddTenant}>
                {creatingTenants || storeloading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  "Confirm"
                )}
              </Btn>
            </Stack>
          </div>
        </>
      )}
    </>
  );
};

// -----------------------------------------------------
// -----------------------------------------------------
// ADD TENANTS BY BIO DATA
// ----------------------------------------------------
// ----------------------------------------------------

const AddByBio = ({ backToOptions, closeModal, apartmentId, propertyId }) => {
  const dispatch = useDispatch();
  //Store
  const creatingTenants = useSelector((state) => state.tenant.creatingTenants);
  const apartmentTenant = useSelector((state) => state.tenant.apartmentTenant);
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  // const fileInputRef = useRef();

  const [tenantBioData, setTenantBioData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    image: null,
    imagePreview: avatar
  });
  const [errors, setErrors] = useState({
    email: false,
    firstName: false,
    lastName: false,
    phoneNumber: false
  });

  //Open users system folder selection, in order to select picture
  // const handleTriggerImgSelection = (e) => {
  //   e.preventDefault();
  //   fileInputRef.current.click();
  // };

  //When user types in tenant specific inputs
  const handleOnChangeTenantInput = useCallback(
    (e) => {
      let { value, name } = e.target;

      //Numeric validation for phone Number
      if (name === "phoneNumber") {
        value = value.match(/\d/g) || []; //allow only digits, NOTE: match could return null hence the empty array second option
        value = value.join("");
        if (value.length === 12) return;
      }

      setTenantBioData((prevState) => {
        return {
          ...prevState,
          [name]: value
        };
      });
    },
    [tenantBioData]
  );

  //Selecting an image from users system when user picks an image from system
  const handleSelectTenantImg = (e) => {
    const file = e.target.files[0];

    setTenantBioData((prevState) => {
      return {
        ...prevState,
        image: file
      };
    });

    //If user did not select any file, get out of function
    if (file === undefined) return;

    const reader = new FileReader();

    reader.onload = function () {
      setTenantBioData((prevState) => {
        return {
          ...prevState,
          imagePreview: reader.result
        };
      });
    };

    reader.readAsDataURL(file);
  };

  //Setting errors
  const handleSetErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: true
      };
    });
  }, []);

  //removing errors(NOTE: this was not merged into the handleSetErrors function because of some edge cases)
  const handleRemoveErrors = useCallback((field) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [field]: false
      };
    });
  }, []);

  useEffect(() => {
    const { email, phoneNumber, firstName, lastName } = tenantBioData;

    if (email.length !== 0) {
      handleRemoveErrors("email");
    }

    if (firstName.length !== 0) {
      handleRemoveErrors("firstName");
    }

    if (lastName.length !== 0) {
      handleRemoveErrors("lastName");
    }

    if (phoneNumber.length !== 0) {
      handleRemoveErrors("phoneNumber");
    }
  }, [
    tenantBioData.email,
    tenantBioData.firstName,
    tenantBioData.lastName,
    tenantBioData.phoneNumber
  ]);

  const handleAddTenant = () => {
    if (tenantBioData.firstName.length === 0) {
      return handleSetErrors("firstName");
    }
    if (tenantBioData.lastName.length === 0) {
      return handleSetErrors("lastName");
    }
    if (tenantBioData.phoneNumber.length === 0 && tenantBioData.email.length === 0) {
      return dispatch(alertError("Please provide a valid Email or Phone Number"));
    }

    const formData = new FormData();
    formData.append("apartment_id", `${apartmentId}`);
    formData.append("method_of_invitation", BYBIO);
    if (tenantBioData.email) {
      formData.append("email", tenantBioData.email);
    }
    if (tenantBioData.phoneNumber) {
      formData.append("phone", tenantBioData.phoneNumber);
    }
    formData.append("first_name", tenantBioData.firstName);
    formData.append("last_name", tenantBioData.lastName);

    dispatch(
      addTenantByBioData(formData, () => {
        dispatch(getTenantsUnderPropertyManager(user.liveizy_id));
        dispatch(getTenantsApartment(user.liveizy_id));
      })
    );
  };

  const tenantDetailsCopy =
    apartmentTenant?.liveizy_id &&
    apartmentTenant?.temporary_password &&
    `Liveizy ID: ${apartmentTenant?.liveizy_id}
Temporary Password: ${apartmentTenant?.temporary_password}
  `;
  return (
    <>
      {apartmentTenant !== null ? (
        <>
          <div
            style={{
              maxWidth: "600px",
              width: "100%",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
              gap: "1rem",
              color: "#052137",
              fontSize: "0.9rem"
            }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="200"
              height="200"
              viewBox="0 0 258 258"
              fill="none">
              <path
                d="M1.50195 129C1.50195 112.256 4.79984 95.6769 11.2073 80.2079C17.6148 64.7388 27.0064 50.6834 38.8458 38.8439C50.6853 27.0044 64.7408 17.6128 80.2098 11.2054C95.6788 4.79789 112.258 1.5 129.002 1.5C145.745 1.5 162.325 4.79789 177.794 11.2054C193.263 17.6128 207.319 27.0044 219.158 38.8439C230.998 50.6834 240.389 64.7388 246.797 80.2079C253.204 95.6769 256.502 112.256 256.502 129C256.502 162.815 243.069 195.245 219.158 219.156C195.247 243.067 162.817 256.5 129.002 256.5C95.1868 256.5 62.7567 243.067 38.8458 219.156C14.9349 195.245 1.50195 162.815 1.50195 129Z"
                fill="#0EC00A"
              />
              <path
                d="M72.335 129.001L114.835 171.501L185.668 100.668"
                stroke="white"
                stroke-width="13.13"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.50195 129C1.50195 112.256 4.79984 95.6769 11.2073 80.2079C17.6148 64.7389 27.0064 50.6834 38.8458 38.8439C50.6853 27.0044 64.7408 17.6128 80.2098 11.2054C95.6788 4.79789 112.258 1.5 129.002 1.5C145.745 1.5 162.325 4.79789 177.794 11.2054C193.263 17.6128 207.319 27.0044 219.158 38.8439C230.998 50.6834 240.389 64.7389 246.797 80.2079C253.204 95.6769 256.502 112.256 256.502 129C256.502 162.815 243.069 195.245 219.158 219.156C195.247 243.067 162.817 256.5 129.002 256.5C95.1868 256.5 62.7567 243.067 38.8458 219.156C14.9349 195.245 1.50195 162.815 1.50195 129V129Z"
                stroke="white"
                stroke-width="2.134"
                stroke-linecap="round"
              />
            </svg>

            <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
              Tenant Added Successfully
            </p>
            <div>
              <p style={{ color: "#C8202D", textAlign: "center" }}>
                Important! <em>Please Copy the Login Details of the Tenant below.</em>
              </p>

              <p>
                Liveizy Id:{" "}
                <span style={{ color: "#10609f" }}>{apartmentTenant?.liveizy_id}</span>
              </p>
              <p>
                Temporary Password:{" "}
                <span style={{ color: "#10609f" }}>
                  {apartmentTenant?.temporary_password}
                </span>
              </p>
            </div>

            <Button
              type="button"
              variant="outlined"
              onClick={() =>
                copyTextToClipboard(tenantDetailsCopy, () =>
                  dispatch(alertSuccess("Tenant Login Details Copied"))
                )
              }>
              Copy Tenant Login Details
            </Button>

            <Button
              type="button"
              variant="contained"
              onClick={() => {
                closeModal();
                dispatch(getPropertyById(propertyId, "charges", "tenant"));
              }}>
              Close
            </Button>
          </div>
        </>
      ) : (
        <>
          <Title>Enter Tenant Details</Title>
          <Grid container columnSpacing={2} padding={2}>
            <Grid item xs={12} sm={6}>
              <CustomInput
                name={"firstName"}
                label={"Tenant's First Name"}
                placeholder={"Enter Tenant's First Name"}
                value={tenantBioData.firstName}
                handleOnChange={handleOnChangeTenantInput}
                errors={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                name={"lastName"}
                label={"Tenant's Last Name"}
                placeholder={"Enter Tenant's Last Name"}
                value={tenantBioData.lastName}
                handleOnChange={handleOnChangeTenantInput}
                errors={errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomInput
                name={"email"}
                type={"email"}
                label={"Tenant's Email"}
                placeholder={"Enter Tenant's Email"}
                value={tenantBioData.email}
                handleOnChange={handleOnChangeTenantInput}
                errors={errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomInput
                name={"phoneNumber"}
                label={"Tenant's Phone Number"}
                placeholder={"Enter Tenant's Phone Number"}
                value={tenantBioData.phoneNumber}
                handleOnChange={handleOnChangeTenantInput}
                errors={errors.phoneNumber}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                backgroundImage: `url(${tenantBioData.imagePreview})`,
                marginRight: "20px",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100px",
                height: "100px",
                borderRadius: "50%"
              }}></div>
            <input
              name="TenantCoverImage"
              hidden
              type="file"
              accept=".jpg, .jpeg, .png"
              ref={fileInputRef}
              onChange={handleSelectTenantImg}
            />
            <button
              style={{
                borderRadius: "5px",
                padding: "8px 15px",
                fontSize: "10px",
                fontWeight: "700",
                border: "0px",
                color: "#043B5C",
                backgroundColor: "#E8F0F9"
              }}
              onClick={handleTriggerImgSelection}
              type="button">
              Upload Tenant's Image
            </button>
          </div>
        </Grid> */}
          </Grid>
          <Stack direction={"row"} spacing={2} margin={2} justifyContent="center">
            <Btn
              variant="outlined"
              onClick={backToOptions}
              disabled={creatingTenants || loading}>
              Back
            </Btn>
            <Btn
              sx={{ background: "#10609f", width: "auto" }}
              disabled={creatingTenants || loading}
              variant="contained"
              onClick={handleAddTenant}>
              {creatingTenants || loading ? (
                <CircularProgress size={16} color="inherit" />
              ) : (
                "Add Tenant"
              )}
            </Btn>
          </Stack>
        </>
      )}
    </>
  );
};

export default AddTenantModal;
