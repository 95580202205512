import SubHeadingUnderline from "#/association/components/SubHeadingUnderline";
import SubtitleSubtext from "#/association/components/SubtitleSubtext";
import BlueGradientJumbotron from "#/association/components/jumbotrons/BlueGradientJumbotron";
import { toCurrency } from "#/components/utils/util";
import MonthlyRentBlock from "#/dashboard/components/monthlyrent/MonthlyRentBlock";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { propertyServiceApi } from "#/apis/propertyServiceApi";
import { CircularProgress } from "@material-ui/core";
import { getMonthlyRentDetails } from "#/store/actions/tenant.actions";

const PropertyDashboard = ({ view, liveizyID }) => {
  const dispatch = useDispatch();
  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);
  const [apartmentInfo, setApartmentInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchApartmentInfo = async () => {
    try {
      const response = await propertyServiceApi().get("/apartment/info");
      setApartmentInfo(response.data.data);
    } catch (error) {
      console.error("Error fetching apartment info", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApartmentInfo();
  }, []);

  useEffect(() => {
    if (liveizyID) {
      dispatch(getMonthlyRentDetails(liveizyID));
    }
  }, [liveizyID, dispatch]);

  return (
    <div>
      {loading ? ( // Loader condition
        <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
          <CircularProgress />
        </div>
      ) : (
        <>
          <SubHeadingUnderline text="Property Details" />
          <BlueGradientJumbotron className="blue-banner" marginTop={3}>
            <div className="custom-grid">
              <SubtitleSubtext
                title={"Total Apartment"}
                text={apartmentInfo?.apartmentCount}
              />
              <SubtitleSubtext title={"Total Rent"} text={apartmentInfo?.rentCount} />
              <SubtitleSubtext
                title={"Total Occupied"}
                text={apartmentInfo?.occuipiedCount}
              />
              <SubtitleSubtext title={"Total Vacant"} text={apartmentInfo?.vacantCount} />
              <SubtitleSubtext
                title={"Last Rent Paid"}
                text={toCurrency(apartmentInfo?.lastRentPaid)}
              />
              <SubtitleSubtext
                title={"Rent Payable"}
                text={toCurrency(apartmentInfo?.totalRentPayable)}
              />
              <SubtitleSubtext
                title={"Received Rent"}
                text={toCurrency(apartmentInfo?.totalRentPaid)}
              />
              <SubtitleSubtext
                title={"Paid Out"}
                text={toCurrency(apartmentInfo?.totalRentPaid)}
              />
            </div>
          </BlueGradientJumbotron>

          <div className="tw-flex tw-flex-col tw-gap-7 tw-mt-16">
            <SubHeadingUnderline text="Monthly Rent" />
            <MonthlyRentBlock
              view={view}
              details={monthlyRentDetail[0]}
              monthlyRentDetails={monthlyRentDetail}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyDashboard;
