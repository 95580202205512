import React from "react";
import ProfileInformation from "./components/profile-information/profile-component.jsx";
import BusinessInformation from "./components/business-information/business-component";
import { NavLink, Route, Redirect, useRouteMatch } from "react-router-dom";
import RentReminder from "./components/rent-reminder/index";
import useWindowSize from "react-use/lib/useWindowSize";
import BackComponent from "#/components/propertycomponents/back-component";
import { Styles } from "./styles";
import ManageRole from "./components/role/role.jsx";
import { useSelector } from "react-redux";
import clsx from "clsx";
import OtherInformation from "./components/other-information/OtherInformation.jsx";
// import AuthSettingsIcon from "../../assets/svg/auth-settings.svg";
import ProfileInfoIcon from "../../assets/svg/profile-info.svg";
import OtherInfoIcon from "../../assets/svg/other-info.svg";
import ManageRolesIcon from "../../assets/svg/manage-roles.svg";
import BusinessInfoIcon from "../../assets/svg/bussiness-info.svg";
import LandLordInfo from "./components/business-information/landlord-info/LandLordInfo.jsx";

// import { BiUserCircle } from "react-icons/bi";
// import { CgUserList } from "react-icons/cg";

const AccountSettings = () => {
  let { path, url } = useRouteMatch();
  const matchesFirstRoute = useRouteMatch(`${url}/profile`);
  const matchesFifthRoute = useRouteMatch(`${url}/other-info`);
  const matchcedSecondRoute = useRouteMatch(`${url}/business-info`);
  const matchesFourthRoute = useRouteMatch(`${url}/landlord/business-info`);
  const matchcedThridRoute = useRouteMatch(`${url}/roles`);
  const windowSize = useWindowSize();
  const role = useSelector((state) => state.auth.user.roles);
  const classes = Styles();

  return (
    <div className={classes.root}>
      <div className="wrapper">
        <nav
          className={classes.settingsTab}
          style={{
            transform:
              windowSize.width < 999
                ? matchesFirstRoute ||
                  matchesFifthRoute ||
                  matchesFifthRoute ||
                  matchcedSecondRoute ||
                  matchcedThridRoute
                  ? "translateX(-100vw)"
                  : "translateX(0px)"
                : "translateX(0px)"
          }}>
          <div className="settings">
            {/* <h3 className="settings__header"></h3> */}
            <NavLink
              className="settings__data"
              activeClassName="active"
              to={`${url}/profile`}>
              Profile Information
              <div className="icon-wrapper">
                <img className="icon-size" src={ProfileInfoIcon} alt="Icon" />
              </div>
            </NavLink>

            <NavLink
              className="settings__data"
              activeClassName="active"
              to={`${url}/other-info`}>
              Other Information
              <div className="icon-wrapper">
                <img className="icon-size" src={OtherInfoIcon} alt="Icon" />
              </div>
            </NavLink>

            {role.includes("Agent") && (
              <NavLink
                className="settings__data"
                activeClassName="active"
                to={`${url}/business-info`}>
                Business Information
                <div className="icon-wrapper">
                  <img className="icon-size" src={BusinessInfoIcon} alt="Icon" />
                </div>
              </NavLink>
            )}
            {(role.includes("Landlord") || role.includes("Agent")) && (
              <NavLink
                className="settings__data"
                activeClassName="active"
                to={`${url}/landlord/business-info`}>
                Landlord Information
                <div className="icon-wrapper">
                  <img
                    className="icon-size"
                    style={{ width: "50px" }}
                    src={ProfileInfoIcon}
                    alt="Icon"
                  />
                </div>
              </NavLink>
            )}
            <NavLink
              className="settings__data"
              activeClassName="active"
              to={`${url}/roles`}>
              Manage Roles
              <div className="icon-wrapper">
                <img className="icon-size" src={ManageRolesIcon} alt="Icon" />
              </div>
            </NavLink>
            {/* <NavLink className="settings__data" activeClassName="active" to={`${url}/tenancy-agreement`}>
                            Tenancy Agreement
                            <div className="icon-wrapper">
                                <ArrowForwardIosIcon fontSize="small" />
                            </div>
                        </NavLink>
                        <NavLink className="settings__data" activeClassName="active" to={`${url}/rent-reminder`}>
                            Rent Reminder
                            <div className="icon-wrapper">
                                <ArrowForwardIosIcon fontSize="small" />
                            </div>
                        </NavLink>
                        {/* <NavLink className="settings__data" activeClassName="active" to={`${url}/estate-admin`}>
                            Estate Admin
                            <div className="icon-wrapper">
                                <ArrowForwardIosIcon fontSize="small" />
                            </div>
                        </NavLink> */}
          </div>
        </nav>

        <div
          className={classes.settingDisplay}
          style={{
            transform:
              windowSize.width < 999
                ? matchesFirstRoute ||
                  matchesFifthRoute ||
                  matchcedSecondRoute ||
                  matchcedThridRoute ||
                  matchesFourthRoute
                  ? "translateX(0px)"
                  : "translateX(-100vw)"
                : "translateX(0px)"
          }}>
          <div className="mobile-header">
            <div className="mobile-header__back">
              <BackComponent />
            </div>
          </div>
          <div className={clsx("setting__display")}>
            {windowSize.width < 969 ? null : (
              <Route path={path} exact>
                <Redirect to={`${path}/profile`} />
              </Route>
            )}
            <Route path={`${path}/profile`}>
              <ProfileInformation />
            </Route>
            <Route path={`${path}/other-info`}>
              <OtherInformation />
            </Route>
            <Route path={`${path}/business-info`}>
              {role.includes("Agent") ? (
                <BusinessInformation />
              ) : (
                <Redirect to={`${path}/profile`} />
              )}
            </Route>
            <Route path={`${path}/landlord/business-info`}>
              {role.includes("Landlord") || role.includes("Agent") ? (
                <LandLordInfo />
              ) : (
                <Redirect to={`${path}/profile`} />
              )}
            </Route>

            <Route path={`${path}/roles`}>
              <ManageRole />
            </Route>
            <Route path={`${path}/tenancy-agreement`}></Route>
            <Route path={`${path}/rent-reminder`}>
              <RentReminder />
            </Route>
            <Route path={`${path}/estate-admin`}></Route>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
