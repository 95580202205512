import MonthlyRentStatus from "./MonthlyRentStatus";
import MonthlyRent from "./MonthlyRent";

const MonthlyRentBlock = ({
  view,
  statusAction,
  details,
  monthlyRentDetails,
  data,
  apartmentID
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "1rem", margin: "1rem 0" }}>
      {/* SHOWS WHEN MONTHLY RENT DATA IS AVAILABLE */}
      {monthlyRentDetails?.length < 1 ? (
        <MonthlyRent />
      ) : (
        <MonthlyRentStatus
          statusAction={statusAction}
          view={view}
          details={details}
          data={data}
          apartmentID={apartmentID}
          monthlyRentDetails={monthlyRentDetails}
        />
      )}
    </div>
  );
};

export default MonthlyRentBlock;

// //FROM PETER IHIMIRE
// const dispatch = useDispatch();
// const billsList = useSelector(selectTenantBills);
// const loadingBills = useSelector(selectLoadingTenantBills);

// const invoiceList = useSelector(selectTenantInvoices);
// const loadingInvoices = useSelector(selectLoadingTenantInvoices);
// const [invoices, setInvoices] = useState([]);

// // let getBillsSubscription;
// // useEffect(() => {
// //   getBillsSubscription = dispatch(getTenantBills(apartmentId));

// //   return () => {
// //     if (getBillsSubscription && getBillsSubscription.cancel) {
// //       getBillsSubscription.cancel();
// //     }
// //   };
// // }, [apartmentId]);
// console.log("Here we go, invoice list...", invoiceList);
// console.log("Invoice state...", invoices);
// console.log("Monthly-rent detail...", monthlyRentDetail);

// useEffect(() => {
//   let isMounted = true;

//   const fetchData = async () => {
//     try {
//       // const bills = dispatch(getTenantBills(apartmentId));
//       const invoices = dispatch(getTenantInvoices(apartmentId));
//       // console.log("Bills:", bills);
//       console.log("Invoices:", invoices);

//       // Use isMounted to check if the component is still mounted
//       if (isMounted) {
//         // Handle the fetched data
//         setInvoices(invoices);
//       }
//     } catch (error) {
//       // Handle errors
//       console.log(error);
//     }
//   };
//   fetchData();

//   // Cleanup function
//   return () => {
//     isMounted = false; // Set isMounted to false when component unmounts
//   };
// }, [apartmentId, dispatch]);

/* {invoiceList.length > 0 &&
        (lastInvoice?.payment_status === "processing" ? (
          <MonthlyRentStatus details={lastInvoice} apartmentId={apartmentId} />
        ) : monthlyRentDetail.length < 1 ? (
          <FlexColumnMediaQuery sx={{ gap: "1rem" }}>
            <Block>
              <p>
                Rent payment is now easier & rewarding.
                <br />
                Build your credit score faster with our monthly rent plan
              </p>
              <h6>MONTHLY RENT</h6>
              <Button variant="outlined" onClick={navigateForm}>
                START PRE-QUALIFICATION
              </Button>
              <VfdLogo>
                <img src="/assets/dashboard/vfd.svg" alt="Vfd Logo" />
              </VfdLogo>
            </Block>
          </FlexColumnMediaQuery>
        ) : (
          <>
            <MonthlyRentStatus details={monthlyRentDetail[0]} apartmentId={apartmentId} />
          </>
        ))} */

/* {(invoiceList.length > 0 && lastInvoice?.payment_status === "processing") ||
      lastInvoice?.payment_status === "incomplete" ? (
        <MonthlyRentStatus details={lastInvoice} apartmentId={apartmentId} />
      ) : monthlyRentDetail.length < 1 ? (
        // work on here to bring invoice
        <FlexColumnMediaQuery sx={{ gap: "1rem" }}>
          <Block>
            <p>
              Rent payment is now easier & rewarding.
              <br />
              Build your credit score faster with our monthly rent plan
            </p>
            <h6>MONTHLY RENT</h6>
            <Button variant="outlined" onClick={navigateForm}>
              START PRE-QUALIFICATION
            </Button>
            <VfdLogo>
              <img src="/assets/dashboard/vfd.svg" alt="Vfd Logo" />
            </VfdLogo>
          </Block>
        </FlexColumnMediaQuery>
      ) : (
        <>
          <MonthlyRentStatus details={monthlyRentDetail[0]} apartmentId={apartmentId} />
        </>
      )} */
