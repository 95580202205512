/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TablePagination from "@material-ui/core/TablePagination";
import { getAllProperties } from "#/store/actions/property.actions";
import { propertyListingStyles } from "./styles";
import PageHeader from "#/components/propertycomponents/page-header";
import { showNotifications } from "#/store/actions/notification.actions";
import PlansInfoModal from "./components/PlansInfoModal";
import SinglePropertyRibbon from "./components/propertyRibbon/SinglePropertyRibbon";
import SadFaceIconComponent from "#/components/ui-components/sadFaceIcon.component";
import AddButton from "#/components/propertycomponents/button-component";

const PropertiesListing = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleModal, setToggleModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("bothPlans");

  const { allProperties, loading } = useSelector((state) => state.property);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const history = useHistory();

  const classes = propertyListingStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModal = (trueOrFalse, context = "bothPlans") => {
    setCurrentModal(context);
    setToggleModal(trueOrFalse);
  };

  useEffect(() => {
    dispatch(getAllProperties());
  }, [dispatch]);

  const RouteToProperty = () => {
    const { is_email_verified } = user;
    if (!is_email_verified) {
      dispatch(showNotifications("Please, verify your email to continue", "error"));
      return;
    }
    history.push(`/create/property`);
  };

  return (
    <div className={`property-listing-page ${classes.root}`} id="property-listing-page">
      {toggleModal && <PlansInfoModal {...{ currentModal, handleModal }} />}

      {/* HEADER */}
      <PageHeader>
        <h3>Properties</h3>
      </PageHeader>

      {/* SEARCH BOXES */}
      <div className={`plp-filter-block ${classes.search_container}`}>
        <div className="plp-lipman-ad">
          <p>
            You can now guarantee your rent with our{" "}
            <a
              title="learn more"
              className="plp-la-link"
              onClick={() => handleModal(true, "lipman")}
              href="#">
              LiPMaN
            </a>{" "}
            and{" "}
            <a
              title="learn more"
              className="plp-la-link"
              onClick={() => handleModal(true, "lirem")}
              href="#">
              LiReM
            </a>{" "}
            plans.
          </p>
          <Button
            onClick={() => handleModal(true, "bothPlans")}
            className="plp-la-btn"
            variant="contained"
            color="primary">
            Create a Plan
          </Button>
        </div>
        <div className={classes.right_section}>
          <Button className={classes.btn} onClick={RouteToProperty}>
            <AddIcon className="icon" />
            <span className="btn_name">New Property</span>
          </Button>
        </div>
      </div>

      {/* TABLE */}
      <section style={{ marginTop: "40px" }}>
        {!loading && !allProperties.length && (
          <SadFaceIconComponent>
            <p>You have not added any property</p>
            <AddButton />
          </SadFaceIconComponent>
        )}
        {loading ? (
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <CircularProgress />
          </div>
        ) : (
          allProperties
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((property) => (
              <SinglePropertyRibbon key={property.id} property={property} />
            ))
        )}
        {allProperties.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
            component="div"
            count={allProperties.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="paginate"
          />
        )}
      </section>
    </div>
  );
};

export default PropertiesListing;
