import React, { useEffect, useMemo, useState } from "react";
import { Box, Divider, Grid, TablePagination } from "@material-ui/core";
import { useStyles, TypographyText } from "./izyinvestPlanStyles";
import { useDispatch, useSelector } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  fundIzyvest,
  getIzyInvestBalance,
  getIzyInvestHistory,
  getIzyPlan,
  selectHistoryPlanId,
  selectIzyInvestHistory,
  selectIzyInvestPlans,
  updateIzyvestPlan
} from "../../../store/actions/izyinvest.actions";
import { useHistory } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import { stringToNumberMoney, toCurrency } from "../../../components/utils/util";
import Button from "#/shared/components/buttons/Button";
import arrowDown from "../../../assets/other images/arrow-down.svg";
import useMediaQuery from "#/shared/hooks/useMediaQuery/useMediaQuery";
// import settingsIcon from "../../../assets/other images/settings-icon.svg";
import savingsIcon from "../../../assets/other images/savings-icon.svg";
import searchIcon from "../../../assets/other images/icons8-search(1).svg";
import { RightArrowIcon, SettingsIcon } from "#/assets/icons/Icons";
import Modal from "#/shared/components/Modal/Modal";
import "./izyinvestplan.scss";
import { _formatCurrency, _formatDaysToDate } from "#/lib/validation";
import {
  Banks,
  selectIzyPayBalance,
  ValidateNuban
} from "#/store/actions/izypay.actions";
import { useForm } from "react-hook-form";
import { ListOfBanksInNigeria } from "#/constants";
import { walletServiceApi } from "#/apis/izyPayApi";

export const IzyinvestPlans = ({ page, setPage, setPlan }) => {
  const [toggleActivePlan, setToggleActivePlan] = useState(false);
  const [toggleCompletedPlan, setToggleCompletedPlan] = useState(false);
  const [fundModalState, setFundModalState] = useState(null);
  const [settingsModalState, setSettingsModalState] = useState(null);
  const [selectedFundPlan, setSelectedFundPlan] = useState(null);
  const [selectedSettingsPlan, setSelectedSettingsPlan] = useState(null);
  const [banks, setBanks] = useState([]);

  console.log(settingsModalState);

  // const [selectedPlan, setSelectedPlan] = useState({
  //   // id: selectedFundPlan.id,
  //   bankName: "",
  //   accountName: "",
  //   accountNumber: ""
  // });
  const [amount, setAmount] = useState(null);
  // const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [visible, setVisible] = useState(4);
  const [visibleCompletedPlans, setVisibleCompletedPlans] = useState(4);
  const [planHistory, setPlanHistory] = useState([]);
  // const [activePlans, setActivePlans] = useState([]);
  // const [completedPlans, setCompletedPlans] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const plans = useSelector(selectIzyInvestPlans);
  const izyInvestHistory = useSelector(selectIzyInvestHistory);
  const izyPayBalance = useSelector(selectIzyPayBalance);
  const store = useSelector((state) => state.paystack);
  const izypayStore = useSelector((store) => store.izypay);

  console.log(izyInvestHistory);

  useEffect(() => {
    // Fetch plans from API
    walletServiceApi()
      .get(`/user/banks`)
      .then((response) => {
        const data = response.data?.data?.banks;
        setBanks(data);
      })
      .catch((error) => {
        console.error("Error fetching data:");
        // Handle errors appropriately
      });
  }, []);

  // const getBanks = useSelector(Banks);

  // const izyInvestHistory = (state) =>
  //   state.izyinvest.getIzyInvestHistory(fundModalState.id);

  const form = useForm();
  const { register, handleSubmit, watch } = form;

  // const values = watch("amount");

  // const { loading } = useSelector((store) => store.izyinvest);

  let userPlans =
    plans.sort(
      (a, b) => Date.parse(new Date(b.created_at)) - Date.parse(new Date(a.created_at))
    ) || [];

  const history = useHistory();
  const isMobile = useMediaQuery("(max-width:768px)");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (bankName && accountNumber.length === 10) {
      // fetch name
      console.log({
        bankName,
        accountNumber
      });
      dispatch(
        ValidateNuban(
          {
            bank_code: bankName,
            account_number: accountNumber
          },
          () => {
            console.log("completed");
          }
        )
      );
    }
  }, [accountNumber, accountName, dispatch, bankName]);

  useEffect(() => {
    dispatch(getIzyPlan());

    updateUserPlan();
    // getPlanHistory();
  }, []);

  useEffect(() => {
    settingsModalState?.account_name && setAccountName(settingsModalState?.account_name);
    settingsModalState?.account_number &&
      setAccountNumber(settingsModalState?.account_number);
    settingsModalState?.bank_id && setBankName(settingsModalState?.bank_id);
  }, [
    settingsModalState?.account_name,
    settingsModalState?.account_number,
    settingsModalState?.bank_id
  ]);

  useEffect(() => {
    if (izypayStore.nubanDetails && izypayStore.nubanDetails.accountName) {
      setAccountName(izypayStore.nubanDetails.accountName);
    }
  }, [izypayStore.nubanDetails]);
  // useEffect(() => {
  //   dispatch(izyInvestHistory);
  // }, []);
  // console.log(plans);

  const updateUserPlan = async () => {
    dispatch(getIzyInvestHistory("8a270a05-403e-497d-9c6e-b90daa35395e"));
  };

  const activePlan = (planId, planName, date, minimumDep) => {
    setPlan(planId, planName, date, minimumDep);
    dispatch(getIzyInvestBalance(planId));
  };
  const createPlan = () => {
    history.push("/izyinvest");
  };
  function toTitleCase(str) {
    return str;
    // return str.replace(/\w\S*/g, function (txt) {
    //   return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    // });
  }

  // const loadMore = () => {
  //   if (visible) {
  //     setVisible((prev) => prev + 4);
  //   } else {
  //     setVisibleCompletedPlans((prev) => prev + 4);
  //   }
  // };

  const fundPlan = () => {
    if (amount === null) {
      return setFormErrors("Field is required");
    }

    // if (checkLimitedAmount) {
    //   return setFormErrors("Amount must not be lower than the first deposit");
    // }

    dispatch(
      fundIzyvest(
        {
          planId: fundModalState.id,
          amount
        },
        () => {
          history.push({ pathname: "/wallet", state: { openizyInvest: false } });
          // history.push("/wallet");
          console.log("submitted izyinvest");
          setSelectedFundPlan(null);
          setAmount(null);
          // setSelectedPlan({ fundPlan: null });
        },
        (err) => {
          console.log("Failed", err.response.data);
        }
      )
    );
    // console.log(
    //   dispatch(
    //     fundIzyvest(data, () => {
    //       console.log("deposited");
    //     })
    //   )
    // );
    // let amount;
    // if (vals) amount = stringToNumberMoney(vals.amount);
    // if (amount < 1 || loading) {
    //   return;
    // } else {
    //   // const { amount } = vals;
    //   const data = { amount, planId };
    //   dispatch(
    //     fundIzyvest(data, () => {
    //       dispatch(getIzyInvestBalance(planId));
    //       setSelectedPlan(null);
    //     })
    //   );
    // }
    // dispatch(
    //   fundIzyvest(data, () => {
    //     dispatch(getIzyInvestBalance(planId));
    //     setSelectedPlan(null);
    //   })
    // );
  };

  // const getPlanHistory = () => {
  //   const planId = plans.map((el) => el.id);

  //   const pl = dispatch(
  //     getIzyInvestHistory({
  //       planId
  //     })
  //   );

  //   setPlanHistory(pl);
  // };

  // console.log(planHistory);

  const updatePlan = () => {
    if (!bankName || !accountName || !accountNumber) {
      return setFormErrors("Field is required");
    }

    const bank_id = ListOfBanksInNigeria.map((el) => el.id);

    dispatch(
      updateIzyvestPlan(
        {
          planId: settingsModalState.id,
          bank_id,
          account_name: accountName,
          account_number: accountNumber
        },
        () => {
          console.log("submitted izyinvest");
          setSelectedSettingsPlan(null);
          // setSelectedPlan({ settingsPlan: null });
        },
        (err) => {
          console.log("Failed", err.response.data);
        }
      )
    );
  };

  const getActivePlans = () => {
    let activeUserPlan = [];
    plans.forEach((el) => {
      if (el.active === 1) {
        activeUserPlan.push({
          ...el
        });
      }
    });

    return activeUserPlan;
  };

  const getCompletedPlans = () => {
    let completedUserPlan = [];
    plans.forEach((el) => {
      if (el.active === 0) {
        completedUserPlan.push({
          ...el
        });
      }
    });

    return completedUserPlan;
  };

  const activeUserPlans = getActivePlans();
  const completedUserPlans = getCompletedPlans();

  // const checkLimitedAmount = useMemo(
  //   () => amount < _formatCurrency(fundModalState?.plan?.min_init_deposit),
  //   [amount, fundModalState?.plan?.min_init_deposit]
  // );

  return (
    <section>
      {isMobile && (
        <div className="tw-flex tw-items-start tw-justify-normal tw-w-full tw-px-5 tw-mb-10">
          <div className="tw-border-[#10609F] tw-w-full tw-border tw-border-solid tw-rounded-[14px] tw-shadow-[0px_2.244px_12.901px_-2.804px_rgba(0,0,0,0.25)] tw-py-[51px] tw-px-[60px]">
            <div>
              <Button
                type={"default"}
                noMargin={true}
                text={"ADD NEW PLAN"}
                onClick={() => history.push("/izyinvest")}
                width={"100%"}
                size={"small"}
              />
            </div>
          </div>
        </div>
      )}

      <div className="tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)] md:tw-shadow-none tw-bg-white md:tw-bg-transparent md:tw-rounded-none tw-rounded-[19px]">
        <div className="tw-flex tw-items-center md:tw-p-0 tw-px-4 tw-py-[21px] tw-justify-between tw-w-full md:tw-border-b-[#052137] md:tw-border-solid md:tw-border-b md:tw-pb-4">
          <div className="tw-flex tw-gap-1">
            <p className="tw-text-[#052137] tw-text-sm md:tw-text-2xl tw-font-semibold">
              Active Plan
            </p>
            <span className="tw-text-[#C8202D] tw-text-sm md:tw-hidden tw-block tw-font-semibold">
              ({activeUserPlans?.length})
            </span>
          </div>
          <div className="tw-hidden md:tw-block">
            <Button
              type={"primary"}
              noMargin={true}
              text={"ADD NEW PLAN"}
              onClick={() => history.push("/izyinvest")}
              width={""}
            />
          </div>

          <div
            onClick={() => setToggleActivePlan(!toggleActivePlan)}
            className={`${
              toggleActivePlan ? "-tw-rotate-90" : "tw-rotate-0"
            } cursor-pointer transition duration-500 ease-in-out tw-block md:tw-hidden`}>
            <img src={arrowDown} alt="arrowdown" />
          </div>
        </div>

        {!isMobile || toggleActivePlan ? (
          <div className="tw-mt-[40px] tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-x-[55px] tw-gap-y-[45px] tw-px-5 md:tw-px-0 tw-pb-5 md:tw-pb-0">
            {activeUserPlans.slice(0, visible).map((plan) => (
              <div
                key={plan.id}
                className="tw-border-[#ffffff] tw-flex tw-flex-col tw-gap-5 md:tw-border-[#10609F] tw-p-5 md:tw-px-10 md:tw-py-5 tw-border-solid tw-border-[2px] tw-shadow-[0px_4px_23px_-5px_rgba(0,0,0,0.25)] tw-rounded-[26px]">
                <div className="tw-flex tw-items-center tw-justify-between">
                  <div className="tw-flex tw-items-center">
                    <img src={savingsIcon} alt="savings-icon" />

                    <p className="tw-text-[#10609F] md:tw-text-lg md:tw-font-bold tw-text-[9px] tw-font-semibold">
                      {plan?.plan?.plan}
                      <span className="tw-text-[#25CC22] tw-ml-3">
                        {plan.monthly_interest}%
                      </span>
                    </p>
                  </div>

                  <div
                    className="tw-cursor-pointer"
                    onClick={() => {
                      setSelectedSettingsPlan(plan.izyinvest_plan_id);
                      // setSelectedPlan({ settingsPlan: plan.izyinvest_plan_id });
                      setSettingsModalState(plan);
                    }}>
                    <SettingsIcon
                      color={isMobile ? "#10609F" : "#C8202D"}
                      width={isMobile ? "25px" : "40px"}
                      height={isMobile ? "25px" : "40px"}
                    />
                  </div>
                </div>

                <div className="tw-flex tw-items-center tw-justify-between md:tw-justify-normal md:tw-gap-[126px]">
                  <div>
                    <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-xs md:tw-text-base tw-font-normal">
                      Available Balance
                    </p>
                    <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-base md:tw-text-[25px] tw-font-bold">
                      {_formatCurrency(plan.available_balance)}
                    </p>
                  </div>
                  <div>
                    <p className="md:tw-text-[10px] tw-text-xs tw-font-normal">
                      Payment Date
                    </p>
                    <p className="md:tw-text-[15px] tw-text-xs tw-text-[rgba(5,33,55,0.90)] tw-font-semibold">
                      {plan.end_date}
                    </p>
                  </div>
                </div>

                <div className="tw-flex tw-flex-col md:tw-gap-0 tw-gap-6 md:tw-flex-row tw-justify-between tw-items-start md:tw-items-center">
                  <div>
                    <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-xs tw-font-normal">
                      Total Contribution
                    </p>
                    <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-sm tw-font-semibold">
                      {_formatCurrency(plan.contributory_balance)}
                    </p>
                  </div>

                  <div className="md:tw-w-[50%] tw-w-full tw-flex tw-justify-center md:tw-justify-normal">
                    <Button
                      type={"primary"}
                      noMargin={true}
                      text={"FUND PLAN"}
                      onClick={() => {
                        console.log(plan);
                        setSelectedFundPlan(plan.izyinvest_plan_id);
                        // setSelectedPlan({ fundPlan: plan.izyinvest_plan_id });
                        setFundModalState(plan);
                      }}
                      width={!isMobile ? "100%" : ""}
                    />
                  </div>
                </div>
              </div>
            ))}
            {isMobile && (
              <>
                {activeUserPlans.length > visible ? (
                  <div className="tw-flex tw-items-center tw-justify-center tw-mb-[20px]">
                    <Button
                      type={"primary"}
                      noMargin={true}
                      text={"LOAD MORE"}
                      onClick={() => setVisible((prev) => prev + 4)}
                      width={""}
                    />
                  </div>
                ) : null}
              </>
            )}
            {/* <div className="tw-border-[#ffffff] tw-flex tw-flex-col tw-gap-5 md:tw-border-[#10609F] tw-p-5 md:tw-px-10 md:tw-py-5 tw-border-solid tw-border-[2px] tw-shadow-[0px_4px_23px_-5px_rgba(0,0,0,0.25)] tw-rounded-[26px]">
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-flex tw-items-center">
                  <img src={savingsIcon} alt="savings-icon" />

                  <p className="tw-text-[#10609F] md:tw-text-lg md:tw-font-bold tw-text-[9px] tw-font-semibold">
                    Rent Savings <span className="tw-text-[#25CC22]">+1.85%</span>
                  </p>
                </div>

                <div
                  onClick={(plan) => setSettingsModalState(plan)}
                  className="tw-cursor-pointer">
                  <SettingsIcon
                    color={isMobile ? "#10609F" : "#C8202D"}
                    width={isMobile ? "25px" : "40px"}
                    height={isMobile ? "25px" : "40px"}
                  />
                </div>
              </div>

              <div className="tw-flex tw-items-center tw-justify-between md:tw-justify-normal md:tw-gap-[126px]">
                <div>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-xs md:tw-text-base tw-font-normal">
                    Available Balance
                  </p>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-base md:tw-text-[25px] tw-font-bold">
                    N500,000.00
                  </p>
                </div>
                <div>
                  <p className="md:tw-text-[10px] tw-text-xs tw-font-normal">
                    Payment Date
                  </p>
                  <p className="md:tw-text-[15px] tw-text-xs tw-text-[rgba(5,33,55,0.90)] tw-font-semibold">
                    12-11-2021
                  </p>
                </div>
              </div>

              <div className="tw-flex tw-flex-col md:tw-gap-0 tw-gap-6 md:tw-flex-row tw-justify-between tw-items-start md:tw-items-center">
                <div>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-xs tw-font-normal">
                    Total Contribution
                  </p>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-sm tw-font-semibold">
                    N5,000.00
                  </p>
                </div>

                <div className="md:tw-w-[50%] tw-w-full tw-flex tw-justify-center md:tw-justify-normal">
                  <Button
                    type={"primary"}
                    noMargin={true}
                    text={"FUND PLAN"}
                    onClick={() => {}}
                    width={!isMobile ? "100%" : ""}
                  />
                </div>
              </div>
            </div>
            <div className="tw-border-[#ffffff] tw-flex tw-flex-col tw-gap-5 md:tw-border-[#10609F] tw-p-5 md:tw-px-10 md:tw-py-5 tw-border-solid tw-border-[2px] tw-shadow-[0px_4px_23px_-5px_rgba(0,0,0,0.25)] tw-rounded-[26px]">
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-flex tw-items-center">
                  <img src={savingsIcon} alt="savings-icon" />

                  <p className="tw-text-[#10609F] md:tw-text-lg md:tw-font-bold tw-text-[9px] tw-font-semibold">
                    Rent Savings <span className="tw-text-[#25CC22]">+1.85%</span>
                  </p>
                </div>

                <div>
                  <SettingsIcon
                    color={isMobile ? "#10609F" : "#C8202D"}
                    width={isMobile ? "25px" : "40px"}
                    height={isMobile ? "25px" : "40px"}
                  />
                </div>
              </div>

              <div className="tw-flex tw-items-center tw-justify-between md:tw-justify-normal md:tw-gap-[126px]">
                <div>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-xs md:tw-text-base tw-font-normal">
                    Available Balance
                  </p>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-base md:tw-text-[25px] tw-font-bold">
                    N500,000.00
                  </p>
                </div>
                <div>
                  <p className="md:tw-text-[10px] tw-text-xs tw-font-normal">
                    Payment Date
                  </p>
                  <p className="md:tw-text-[15px] tw-text-xs tw-text-[rgba(5,33,55,0.90)] tw-font-semibold">
                    12-11-2021
                  </p>
                </div>
              </div>

              <div className="tw-flex tw-flex-col md:tw-gap-0 tw-gap-6 md:tw-flex-row tw-justify-between tw-items-start md:tw-items-center">
                <div>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-xs tw-font-normal">
                    Total Contribution
                  </p>
                  <p className="tw-text-[rgba(5,33,55,0.90)] tw-text-sm tw-font-semibold">
                    N5,000.00
                  </p>
                </div>

                <div className="md:tw-w-[50%] tw-w-full tw-flex tw-justify-center md:tw-justify-normal">
                  <Button
                    type={"primary"}
                    noMargin={true}
                    text={"FUND PLAN"}
                    onClick={() => {}}
                    width={!isMobile ? "100%" : ""}
                  />
                </div>
              </div>
            </div> */}
          </div>
        ) : null}

        {!isMobile && (
          <>
            {activeUserPlans.length > visible ? (
              <div className="tw-flex tw-items-center tw-justify-center tw-mt-[40px]">
                <Button
                  type={"primary"}
                  noMargin={true}
                  text={"LOAD MORE"}
                  onClick={() => setVisible((prev) => prev + 4)}
                  width={""}
                />
              </div>
            ) : null}
          </>
        )}
      </div>

      <div className="tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)] md:tw-shadow-none tw-bg-white md:tw-bg-transparent md:tw-rounded-none tw-rounded-[19px]">
        <div className="tw-flex tw-items-center md:tw-p-0 tw-px-4 tw-py-[21px] tw-justify-between tw-w-full tw-mt-10 md:tw-border-b-[#052137] md:tw-border-solid md:tw-border-b md:tw-pb-4">
          <div className="tw-flex tw-gap-1">
            <p className="tw-text-[#052137] tw-text-sm md:tw-text-2xl tw-font-semibold">
              Completed Plans
            </p>
            <span className="tw-text-[#C8202D] tw-text-sm md:tw-hidden tw-block tw-font-semibold">
              ({completedUserPlans?.length})
            </span>
          </div>

          <div
            onClick={() => setToggleCompletedPlan(!toggleCompletedPlan)}
            className={`${
              toggleCompletedPlan ? "-tw-rotate-90" : "tw-rotate-0"
            } cursor-pointer transition duration-500 ease-in-out`}>
            <img src={arrowDown} alt="arrowdown" className="tw-block md:tw-hidden" />
          </div>
        </div>

        {!isMobile || toggleCompletedPlan ? (
          <div>
            {completedUserPlans?.length === 0 ? (
              <div className="tw-h-[200px] md:tw-flex tw-hidden tw-flex-col tw-items-center tw-justify-center">
                <img src={searchIcon} alt="search-icon" />
                <span className="tw-text-[#909090]">no data found</span>
              </div>
            ) : (
              <>
                {completedUserPlans?.slice(0, visibleCompletedPlans)?.map((userPlan) => (
                  <div
                    key={userPlan?.id}
                    className="md:tw-flex tw-items-center tw-gap-20 tw-flex-wrap tw-justify-between md:tw-pr-5 tw-pr-0 tw-mt-8 tw-hidden">
                    <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-6">
                      <h1 className="tw-text-[rgba(5,33,55,0.70)] tw-text-base tw-font-semibold">
                        Type
                      </h1>
                      <p className="tw-text-[#10609F] tw-text-sm tw-font-semibold">
                        {userPlan?.plan?.plan}
                      </p>
                    </div>

                    <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-6">
                      <h1 className="tw-text-[rgba(5,33,55,0.70)] tw-text-base tw-font-semibold">
                        Total Balance
                      </h1>
                      <p className="tw-text-[#052137] tw-text-sm tw-font-semibold">
                        {_formatCurrency(userPlan?.available_balance || 0)}
                      </p>
                    </div>

                    <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-6">
                      <h1 className="tw-text-[rgba(5,33,55,0.70)] tw-text-base tw-font-semibold">
                        Total contribution
                      </h1>
                      <p className="tw-text-[#052137] tw-text-sm tw-font-semibold">
                        {_formatCurrency(userPlan?.contributory_balance || 0)}
                      </p>
                    </div>

                    <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-6">
                      <h1 className="tw-text-[rgba(5,33,55,0.70)] tw-text-base tw-font-semibold">
                        Payout date
                      </h1>
                      <p className="tw-text-[#052137] tw-text-sm tw-font-semibold">
                        {userPlan?.end_date}
                      </p>
                    </div>
                    <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-6">
                      <h1 className="tw-text-[rgba(5,33,55,0.70)] tw-text-base tw-font-semibold">
                        Status
                      </h1>
                      <p className="tw-text-sm tw-font-semibold tw-text-green-500">
                        {userPlan?.active === 0 ? "Completed" : null}
                      </p>
                    </div>

                    {/* <div className="tw-flex tw-flex-col tw-gap-6">
                  <h1 className="tw-text-[rgba(5,33,55,0.70)] tw-text-base tw-font-semibold">
                    Status
                  </h1>
                  <p className="tw-text-[#0FB43D] tw-text-sm tw-font-semibold">
                    {userPlan?.status}
                  </p>
                </div> */}
                  </div>
                ))}
              </>
            )}

            {!isMobile && (
              <>
                {completedUserPlans?.length > visibleCompletedPlans ? (
                  <div className="tw-flex tw-items-center tw-justify-center tw-mt-[40px]">
                    <Button
                      type={"primary"}
                      noMargin={true}
                      text={"LOAD MORE"}
                      onClick={() => setVisibleCompletedPlans((prev) => prev + 4)}
                      width={""}
                    />
                  </div>
                ) : null}
              </>
            )}

            <div className="md:tw-hidden tw-px-5 tw-pb-10">
              {completedUserPlans.length === 0 ? (
                <div className="tw-h-[200px] tw-flex md:tw-hidden tw-flex-col tw-items-center tw-justify-center">
                  <img src={searchIcon} alt="search-icon" />
                  <span className="tw-text-[#909090]">no data found</span>
                </div>
              ) : (
                <>
                  {completedUserPlans.slice(0, visibleCompletedPlans).map((userPlan) => (
                    <div
                      key={userPlan?.id}
                      className="tw-grid tw-grid-cols-[repeat(auto-fill,minmax(120px,1fr))] tw-gap-y-4 tw-border-t-[#052137] tw-border-solid tw-border-t tw-pt-6 tw-mb-6">
                      <div>
                        <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                          Title
                        </p>
                        <p className="tw-text-[#10609F] tw-text-[10px] tw-font-bold">
                          {userPlan?.plan?.plan}
                        </p>
                      </div>

                      <div>
                        <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                          Total Balance
                        </p>
                        <p className="tw-text-[#052137] tw-text-[10px] tw-font-semibold">
                          {_formatCurrency(userPlan?.available_balance)}
                        </p>
                      </div>
                      <div>
                        <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                          Total Contribution
                        </p>
                        <p className="tw-text-[#052137] tw-text-[10px] tw-font-semibold">
                          {_formatCurrency(userPlan?.contributory_balance)}
                        </p>
                      </div>

                      <div>
                        <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                          Payout Date
                        </p>
                        <p className="tw-text-[#052137] tw-text-[10px] tw-font-semibold">
                          {userPlan?.end_date}
                        </p>
                      </div>

                      {/* <div>
                    <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                      Status
                    </p>
                    <p className="tw-text-[#0FB43D] tw-text-[10px] tw-font-semibold">
                      {userPlan?.status}
                    </p>
                  </div> */}
                    </div>
                  ))}
                </>
              )}

              {isMobile && (
                <>
                  {completedUserPlans?.length > visibleCompletedPlans ? (
                    <div className="tw-flex tw-items-center tw-justify-center tw-mt-[40px]">
                      <Button
                        type={"primary"}
                        noMargin={true}
                        text={"LOAD MORE"}
                        onClick={() => setVisibleCompletedPlans((prev) => prev + 4)}
                        width={""}
                      />
                    </div>
                  ) : null}
                </>
              )}
              {/* <div className="tw-grid tw-grid-cols-[repeat(auto-fill,minmax(120px,1fr))] tw-gap-y-4 tw-border-t-[#052137] tw-border-solid tw-border-t tw-pt-6">
                <div>
                  <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">Title</p>
                  <p className="tw-text-[#10609F] tw-text-[10px] tw-font-bold">
                    School fee
                  </p>
                </div>

                <div>
                  <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                    Total Balance
                  </p>
                  <p className="tw-text-[#052137] tw-text-[10px] tw-font-semibold">
                    N503,000.45
                  </p>
                </div>
                <div>
                  <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                    Total Contribution
                  </p>
                  <p className="tw-text-[#052137] tw-text-[10px] tw-font-semibold">
                    N450,000.00
                  </p>
                </div>

                <div>
                  <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                    Payout Date
                  </p>
                  <p className="tw-text-[#052137] tw-text-[10px] tw-font-semibold">
                    12-11-2023
                  </p>
                </div>

                <div>
                  <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">Status</p>
                  <p className="tw-text-[#0FB43D] tw-text-[10px] tw-font-semibold">
                    Paid
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        ) : null}
      </div>

      {selectedFundPlan !== null && (
        <Modal
          buttonText="FUND PLAN"
          // buttonAction={handleSubmit(ActivatePlan)}
          showBackButton
          backButtonText="BACK"
          backButtonAction={() => {
            setSelectedFundPlan(null);
            setFormErrors("");
          }}
          asForm={true}
          formSubmit={handleSubmit(fundPlan)}
          // btnWidth={"185px"}
          width="892px"
          maxHeight="500px">
          <div className="tw-py-6">
            <p className="tw-text-center tw-text-2xl tw-font-bold tw-text-[#052137]">
              {fundModalState?.plan?.description}
            </p>

            <div className="md:tw-bg-[#ffffff] md:tw-shadow-[0px_3.44px_22.36px_-7.74px_rgba(0,0,0,0.25)] tw-rounded-2xl tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-gap-y-4 tw-gap-x-[21px] md:tw-gap-x-0 md:tw-gap-y-0 tw-items-center tw-justify-between tw-py-6 tw-px-0 md:tw-px-[37px] tw-mt-6">
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Monthly Interest
                </p>
                <p className="tw-text-[#25CC22] tw-font-bold tw-text-[10px]">
                  {fundModalState?.plan?.monthly_interest}%
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  First Deposit
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not lower than {_formatCurrency(fundModalState?.plan?.min_init_deposit)}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Recurring Deposit
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not lower than{" "}
                  {_formatCurrency(fundModalState?.plan?.min_recur_deposit)}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Fixed Tenure
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not Before {_formatDaysToDate(fundModalState?.plan?.min_duration)}
                </p>
              </div>
            </div>

            <div className="tw-mt-10 tw-flex tw-flex-col tw-gap-4 tw-items-center tw-justify-center">
              <div className="tw-bg-[#ffffff] tw-w-full md:tw-w-[362px] md:tw-border-0 tw-border-[#10609F] tw-border-solid tw-border-[2px] tw-shadow-[0px_7.048px_45.81px_-15.857px_rgba(0,0,0,0.25)] tw-rounded-[34px] tw-py-[17px] tw-px-[38px]">
                <p className="tw-text-[rgba(5,33,55,0.80)] tw-text-[19px] tw-font-bold">
                  izyPay
                </p>
                <div className="tw-flex tw-items-center tw-mt-[14px] tw-justify-between">
                  <p className="tw-text-[#10609F] tw-text-sm md:tw-text-[28px] tw-font-bold">
                    {_formatCurrency(izyPayBalance)}
                  </p>
                  <div
                    className="tw-flex tw-items-center tw-cursor-pointer tw-gap-3"
                    onClick={() => {
                      setSelectedFundPlan(null);
                      // setSelectedPlan({ fundPlan: null });
                      history.push({
                        pathname: "/wallet",
                        state: { openizyInvest: false }
                      });
                    }}>
                    <p className="tw-text-[#C8202D] tw-underline tw-text-[11px] md:tw-text-sm tw-font-bold">
                      View
                    </p>
                    <RightArrowIcon
                      width={isMobile ? "13px" : "17px"}
                      height={isMobile ? "14px" : "17px"}
                    />
                  </div>
                </div>
              </div>

              {izyPayBalance === 0 && (
                <p className="tw-text-[rgba(200,32,45,0.81)] tw-text-xs md:tw-text-base tw-font-normal tw-text-center md:tw-w-[425px]">
                  You need need to fund your izyPay wallet first to fund your izyInvest
                  plan.
                </p>
              )}
            </div>

            <div className="tw-grid tw-items-center tw-justify-center tw-mt-8 md:tw-mt-10">
              <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                Enter Amount To Fund
              </label>
              <input
                type="text"
                placeholder="N0.00"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                // name="amount"
                // {...register("amount", {
                //   validate: (fieldValues) => {
                //     return !fieldValues ? "please enter amount to fund" : "";
                //   }
                // })}
                className={`tw-w-[319px] tw-bg-gradient-to-l tw-from-[rgba(0,0,0,0.04)] ${
                  formErrors && "tw-border-[red]"
                } tw-to-[rgba(227,225,225,0.08)] md:tw-h-[70px] tw-h-[60px] tw-rounded-[12px] tw-border-[#DCDDDD] tw-border-[2px] tw-border-solid tw-text-[#052137b3] tw-pl-6 tw-outline-none tw-shadow-[0px_4px_24px_-1px_#FFF]`}
              />
              {formErrors && (
                <span className="tw-text-[red] tw-text-[12px]">{formErrors}</span>
              )}

              {/* {checkExcessAmount && (
                <span className="tw-text-[red] tw-text-[12px]">
                  Amount must not be greater than the first deposit
                </span>
              )}

              {checkLimitedAmount && (
                <span className="tw-text-[red] tw-text-[12px]">
                  Amount must not be lower than the first deposit
                </span>
              )} */}
            </div>

            <div className="tw-flex tw-items-center tw-justify-center">
              <div className="tw-bg-[#ffffff] tw-shadow-[0px_7.048px_45.81px_-15.857px_rgba(0,0,0,0.25)] tw-py-6 tw-px-[34px] tw-w-[172px] tw-rounded-[34px] tw-mt-6 tw-flex tw-flex-col tw-gap-2">
                <p className="tw-text-[#000000] tw-text-base tw-font-normal">
                  Payment Date
                </p>
                <p className="tw-text-[#000000] tw-text-xs tw-font-bold">
                  {fundModalState?.end_date}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {selectedSettingsPlan !== null && (
        <Modal
          buttonText="UPDATE"
          // buttonAction={handleSubmit(ActivatePlan)}
          showBackButton
          backButtonText="BACK"
          backButtonAction={() => {
            setSelectedSettingsPlan(null);
            setFormErrors("");
            setSettingsModalState(null);
          }}
          asForm
          formSubmit={handleSubmit(updatePlan)}
          // btnWidth={"185px"}
          width="892px"
          maxHeight="500px">
          <div className="tw-py-6">
            <p className="tw-text-center tw-text-2xl tw-font-bold tw-text-[#052137]">
              {settingsModalState?.plan?.plan}
            </p>

            <div className="md:tw-bg-[#ffffff] md:tw-shadow-[0px_3.44px_22.36px_-7.74px_rgba(0,0,0,0.25)] tw-rounded-2xl tw-grid md:tw-grid-cols-4 tw-grid-cols-2 tw-gap-y-4 tw-gap-x-[21px] md:tw-gap-x-0 md:tw-gap-y-0 tw-items-center tw-justify-between tw-py-6 tw-px-0 md:tw-px-[37px] tw-mt-6">
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Monthly Interest
                </p>
                <p className="tw-text-[#25CC22] tw-font-bold tw-text-[10px]">
                  {settingsModalState?.plan?.monthly_interest}%
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  First Deposit
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not lower than{" "}
                  {_formatCurrency(settingsModalState?.plan?.min_init_deposit)}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Recurring Deposit
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not lower than{" "}
                  {_formatCurrency(settingsModalState?.plan?.min_recur_deposit)}
                </p>
              </div>
              <div className="tw-flex tw-flex-col tw-py-6 tw-px-4 tw-rounded-[19px] tw-w-[153px] tw-gap-1 tw-bg-[#ffffff] md:tw-bg-transparent md:tw-shadow-none tw-shadow-[0px_4px_26px_-9px_rgba(0,0,0,0.25)]">
                <p className="tw-text-[#052137] tw-text-xs tw-font-semibold">
                  Fixed Tenure
                </p>
                <p className="tw-text-[#052137] tw-font-bold tw-text-[10px]">
                  Not Before {_formatDaysToDate(settingsModalState?.plan?.min_duration)}
                </p>
              </div>
            </div>

            <div className="tw-mt-[32px]">
              <p className="tw-text-center tw-text-[#000000] tw-text-base md:tw-text-2xl tw-font-semibold">
                Bank Details
              </p>

              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-16 md:tw-px-10 tw-px-0">
                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Bank Name
                  </label>
                  <div
                    className={`tw-rounded-[12px] tw-px-6 ${
                      formErrors && "tw-border-[red]"
                    } tw-flex tw-items-center tw-shadow-[0px_4px_24px_-1px_#FFF] md:tw-h-[70px] tw-h-[60px] tw-border-[#DCDDDD] tw-border-[2px] tw-border-solid tw-w-full md:tw-w-[319px] tw-bg-gradient-to-l tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)]`}>
                    <select
                      type="text"
                      placeholder="Select your bank"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      className="tw-text-[#052137b3] tw-bg-inherit tw-h-full tw-w-full tw-outline-none">
                      {banks.map((el) => (
                        <option value={el.id} key={el.id}>
                          {el.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {formErrors && (
                    <span className="tw-text-[red] tw-text-[12px]">{formErrors}</span>
                  )}
                </div>

                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Account Number
                  </label>
                  <input
                    type="text"
                    placeholder="Account Number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    className={`md:tw-w-[319px] tw-w-full tw-bg-gradient-to-l ${
                      formErrors && "tw-border-[red]"
                    } tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)] md:tw-h-[70px] tw-h-[60px] tw-rounded-[12px] tw-border-[#DCDDDD] tw-border-[2px] tw-border-solid tw-text-[#052137b3] tw-pl-6 tw-outline-none tw-shadow-[0px_4px_24px_-1px_#FFF]`}
                  />
                  {formErrors && (
                    <span className="tw-text-[red] tw-text-[12px]">{formErrors}</span>
                  )}
                </div>

                <div className="tw-grid tw-mt-8 md:tw-mt-7">
                  <label className="tw-text-[#052137b3] md:tw-text-lg tw-text-xs tw-font-semibold md:tw-font-normal">
                    Account Name
                  </label>
                  <input
                    type="text"
                    placeholder="Account Name"
                    value={accountName}
                    onChange={(e) => setAccountName(e.target.value)}
                    className={`md:tw-w-[319px] tw-w-full tw-bg-gradient-to-l ${
                      formErrors && "tw-border-[red]"
                    } tw-from-[rgba(0,0,0,0.04)] tw-to-[rgba(227,225,225,0.08)] md:tw-h-[70px] tw-h-[60px] tw-rounded-[12px] tw-border-[#DCDDDD] tw-border-[2px] tw-border-solid tw-text-[#052137b3] tw-pl-6 tw-outline-none tw-shadow-[0px_4px_24px_-1px_#FFF]`}
                  />
                  {formErrors && (
                    <span className="tw-text-[red] tw-text-[12px]">{formErrors}</span>
                  )}
                  {izypayStore.loading && (
                    <span className="tw-text-[gray] tw-text-[12px]">
                      Fetching account name
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </section>
    // <React.Fragment>
    //   <div style={{ marginBottom: "1%" }}>
    //     {/* <h6 className="head-color py-1"></h6> */}
    //     <TypographyText variant="h6">izyInvest</TypographyText>
    //   </div>
    //   <Divider style={{ backgroundColor: "#285873" }} />
    //   <Grid container style={{ marginTop: "5%" }}>
    //     <Grid item md={5} sm={5} xs={12} className={classes.createPlan}>
    //       <Box className={classes.boxContainer}>
    //         <Grid style={{ cursor: "pointer" }} onClick={createPlan}>
    //           <TypographyText variant="subtitle1">
    //             <span className={classes.PlusIcon}>+</span>
    //             Create New Invest Plan
    //           </TypographyText>
    //         </Grid>
    //       </Box>
    //     </Grid>
    //     {(rowsPerPage > 0
    //       ? userPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //       : userPlans
    //     ).map((plan, index) => {
    //       let planName = plan?.plan?.plan;
    //       if (planName === "PLATINUM_PLUS") {
    //         planName = "PLATINUM PLUS";
    //       }
    //       return (
    //         <Grid
    //           item
    //           md={5}
    //           sm={5}
    //           xs={12}
    //           style={{ marginLeft: "4%", marginRight: "4%" }}
    //           key={index}>
    //           <Box
    //             className={classes.boxContainer1}
    //             style={{ cursor: "pointer" }}
    //             onClick={() =>
    //               activePlan(plan.id, planName, plan.updated_at, plan.min_recur_deposit)
    //             }>
    //             <Grid item container justifyContent="space-between">
    //               <TypographyText variant="caption" className={classes.planTag}>
    //                 <CalendarMonthIcon />
    //                 {toTitleCase(plan?.end_date)}
    //               </TypographyText>
    //               <TypographyText variant="caption" className={classes.planTag}>
    //                 {toTitleCase(planName)} Plan
    //                 <LockIcon />
    //               </TypographyText>
    //             </Grid>
    //             <Grid container style={{ marginTop: "7%" }}>
    //               <Grid md={8} xs={8} item className={classes.red}>
    //                 {/* <TypographyText variant="caption">RENT SAVING</TypographyText> */}
    //                 <TypographyText variant="h5" style={{ fontWeight: "bold" }}>
    //                   {toCurrency(plan.available_balance)}
    //                 </TypographyText>
    //               </Grid>
    //               <span className={classes.vertical}></span>
    //               <Grid md xs item className={classes.purple}>
    //                 <TypographyText variant="body2">
    //                   {plan.monthly_interest}%
    //                 </TypographyText>
    //                 <TypographyText variant="caption">monthly interest</TypographyText>
    //               </Grid>
    //             </Grid>
    //           </Box>
    //         </Grid>
    //       );
    //     })}
    //   </Grid>
    //   <TablePagination
    //     rowsPerPageOptions={[5, 10]}
    //     component="div"
    //     count={userPlans.length}
    //     rowsPerPage={rowsPerPage}
    //     page={page}
    //     onPageChange={handleChangePage}
    //     onRowsPerPageChange={handleChangeRowsPerPage}
    //   />
    // </React.Fragment>
  );
};
